import $ from 'jquery';
import 'what-input';
import './lib/slick.min';
import './lib/delighters.min';
import './lib/lightbox.min';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();
// Global Nav Position Setting
document.addEventListener('DOMContentLoaded', function () {
  const mq = window.matchMedia('(max-width: 1024px)');

  function checkBreakPoint(mq) {
    if (mq.matches) {
      $('.gNav').appendTo('.spNav');
      $('.subNav').appendTo('.spNav');
      $('.gNav > .menu').removeClass('expanded');
      $('header').attr('data-sticky-container');
      $('.sub-menu').addClass('vertical');
      // $('.gNav > .menu').addClass('align-center');
    } else {
      $('.subNav').appendTo('.title-wrapper');
      $('.gNav').appendTo('.pcNav');
      $('.gNav > .menu').addClass('expanded');
      $('header').removeAttr('data-sticky-container');
      $('.sub-menu').removeClass('vertical');
      // $('.gNav > .menu').removeClass('align-center');
    }
  }
  mq.addListener(checkBreakPoint);
  checkBreakPoint(mq);
});

// Page top
$(function () {
  var pagetop = $('#page_top');
  pagetop.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 300) { //300pxスクロールしたら表示
      pagetop.fadeIn();
    } else {
      pagetop.fadeOut();
    }
  });
});
// Slickの設定
window.onload = function () {
  $('.slick-carousel-wrapper').not('.slick-initialized').slick({
    autoplay: true,
    autoplaySpeed: 3500,
    accessibility: true,
    arrows: true,
    fade: true,
    focusOnSelect: true,
  });
  // Slickの再生・停止
  $('.slick-stop').on('click', function () {
    $('.slick-carousel-wrapper')
      .slick('slickPause')
  });
  $('.slick-play').on('click', function () {
    $('.slick-carousel-wrapper')
      .slick('slickPlay')
  });
}
// スライド　イベント
$(document).ready(function () {
  $('.carousel_slick').slick({
    focusOnSelect: true,
    dots: true,
    autoplay: true,

    pauseOnHover: false,
    arrows: true,
    infinite: true,

    autoplaySpeed: 4800,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: false,
    centerMode: true,
    centerPadding: "15%",
    responsive: [{
      breakpoint: 640,
      settings: {
        centerMode: true,
        centerPadding: "10px",
        // vertical: false
      }
    }]
  });
});
// Slick 再生停止ボタン
$('.btn_stop').on('click', function () {
  $('.carousel_slick').slick('slickPause');
});
$('.btn_play').on('click', function () {
  $('.carousel_slick').slick('slickPlay');
});
// スライド　矢口家
$(document).ready(function () {
  $('.yaguchi_slick').slick({
    focusOnSelect: true,
    dots: true,
    autoplay: true,

    pauseOnHover: false,
    arrows: true,
    infinite: true,

    autoplaySpeed: 4800,
    speed: 300,
    slidesToShow: 2,
    adaptiveHeight: true,
    centerMode: true,
    centerPadding: "15%",
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: "10px",
        // vertical: false
      }
    }]
  });
});
// Slick 再生停止ボタン
$('.btn_stop').on('click', function () {
  $('.yaguchi_slick').slick('slickPause');
});
$('.btn_play').on('click', function () {
  $('.yaguchi_slick').slick('slickPlay');
});


// ページ内リンクの移動時ズレ修正
$(function () {
  const windowWidth = $(window).width();
  // 画面上部の高さ調整
  const headerHight = 30;
  $('a[href^=#]').click(function () {
    // 移動アニメーションの速度
    const speed = 500;
    const href = $(this).attr("href");
    const target = $(href == "#" || href == "" ? 'html' : href);
    const position = target.offset().top - headerHight;
    $('body,html').animate({
      scrollTop: position
    }, speed, 'swing');
    // 移動先にfocusを当てる
    target.focus();
    if (target.is(":focus")) {
      return false;
    } else {
      target.attr('tabindex', '-1');
      target.focus();
    };
    return false;
  });
});
// Object fit のIE/Edge対応
$(function () {
  objectFitImages();
});
// アンカーリンク移動時のズレ修正
$(window).on('load', function() {
	const url = $(location).attr('href'),
	headerHeight = $('header').outerHeight() + 50;
	if(url.indexOf("#") != -1){
		const anchor = url.split("#"),
		target = $('#' + anchor[anchor.length - 1]),
		position = Math.floor(target.offset().top) - headerHeight;
		$("html, body").animate({scrollTop:position}, 0);
	}
});
  $(function() {
    const headerHeight = 200;
    $('a[href^="#"]' + 'a:not(.tab-link)').click(function(){
      const href= $(this).attr("href");
      const target = $(href == "#" || href == "" ? 'html' : href);
      const position = target.offset().top-headerHeight; 
      $("html, body").animate({scrollTop:position}, 200, "swing");
      return false;
    });
  });
